import React from 'react';

const Packages = () => {
  return (
    <section id="ourPakeg" className="ourPakeg">
      <div className="container">
        <div className="main_pakeg_content">
          <div className="row">
            <div className="head_title text-center">
              <h4>Amazing</h4>
              <h3>Delicious</h3>
            </div>
            <div className="single_pakeg_two text-left wow rotateInDownLeft ">
            <div className="col-md-6 col-sm-8 card-dish">
                <div className="single_pakeg_text">
                  <div className="pakeg_title">
                    <h4>Platters</h4>
                  </div>
                  <ul>
                    <div>
                      <div className="dish-header">
                        <span className="dish-name">BBQ Platter</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">50KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                      (ćevapi, bbq sausage, cutlet, liver, burgersteak, chicken fillet, skewers, fries, flatbread)
                      </div>
                    </div>
                    <div>
                      <div className="dish-header">
                        <span className="dish-name">National Mimar platter</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">60KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                      (peppered meat, Herzegovinian pan dish, dolma, japrak, klepe, purée, rice,cream)
                      </div>
                    </div>
                  
                    <div>
                      <div className="dish-header">
                        <span className="dish-name">Vegetarian platter</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">45KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                      (rice, purée, ghivetch, mixed grilled vegetables)
                      </div>
                    </div>
                  
                    <div>
                      <div className="dish-header">
                        <span className="dish-name">Fish platter</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">65KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                      (seabass, bream, trout, Dalmatian garnish, red fillet, vegetables)
                      </div>
                    </div>
                    <a href="dish-menu" class="btn btn-primary margin-top-60">find more</a>

                  
                  </ul>
                </div>
              </div>
            </div>
            <div className="single_pakeg_two text-left wow rotateInDownLeft card-dish">
              <div className="col-md-6 col-sm-8 card-dish">
                <div className="single_pakeg_text">
                  <div className="pakeg_title">
                    <h4>Mimar Homemade Dishes</h4>
                  </div>
                  <ul>
                    <div>
                      <div className="dish-header">
                        <span className="dish-name">Japrak</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">14KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                      (meat,collard,rice,potato)
                      </div>
                    </div>
                    <div>
                      <div className="dish-header">
                        <span className="dish-name">Dolma</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">16KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                      (meat,bell pepper,rice,potato)
                      </div>
                    </div>
                  
                    <div>
                      <div className="dish-header">
                        <span className="dish-name">Mimar Sahan</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">18KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                     (japrak, dolma)
                      </div>
                    </div>
                  
                    <div>
                      <div className="dish-header">
                        <span className="dish-name">Klepe</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">21KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                      (dough, minced meat, cream)
                      </div>
                    </div>
                  
                    <div>
                      <div className="dish-header">
                        <span className="dish-name">Herzegovinian pan dish</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">20KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                      (meat, onion, mustard, rice)
                      </div>
                    </div>

                    <div>
                      <div className="dish-header">
                        <span className="dish-name">Mućkalica</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">21KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                      (meat, vegetables, rice)
                      </div>
                    </div>

                    <div>
                      <div className="dish-header">
                        <span className="dish-name">National Mimar platter</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">60KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                      (japrak, dolma, peppered meat, klepe, Herzegovinian pan dish)
                      </div>
                    </div>
                    <a href="dish-menu" class="btn btn-primary margin-top-60">find more</a>
                  
                  </ul>
                </div>
              </div>
            </div>
            <div className="single_pakeg_two text-left wow rotateInDownLeft card-dish">
            <div className="col-md-6 col-sm-8 card-dish">
                <div className="single_pakeg_text">
                  <div className="pakeg_title">
                    <h4>Grilled Dishes</h4>
                  </div>
                  <ul>
                    <div>
                      <div className="dish-header">
                        <span className="dish-name">Ćevapi</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">14KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                      (meat, onion, flatbread)
                      </div>
                    </div>
                    <div>
                      <div className="dish-header">
                        <span className="dish-name">BBQ Sausage</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">16KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                      (meat, mustard, fries, flatbread)
                      </div>
                    </div>
                  
                    <div>
                      <div className="dish-header">
                        <span className="dish-name">Chicken fillet</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">15KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                      (chicken, sauce, fries, flatbread)
                      </div>
                    </div>
                  
                    <div>
                      <div className="dish-header">
                        <span className="dish-name">Gourmet burger steak</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">20KM</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                      (meat, prosciutto, cheese,fries ,flatbread)
                      </div>
                      <a href="dish-menu" class="btn btn-primary margin-top-60">find more</a>
                    </div>
                  

                  
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>
  );
};

export default Packages;
