import React from 'react';

const About = () => {
  return (
    <section id="abouts" className="abouts">
      <div className="container">
        <div className="row">
          <div className="abouts_content">
            <div className="col-md-6">
              <div className="single_abouts_text text-center wow slideInLeft" data-wow-duration="1s">
                <img src="assets/images/ab.jpg" alt="about us" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="single_abouts_text wow slideInRight" data-wow-duration="1s">
                <h4>About us</h4>
                <p>Restaurant Mimar, located in the heart of Mostar at Mala Tepa bb, offers a unique gastronomic experience that combines traditional and modern culinary techniques.</p>
                <p>Our goal is to provide every guest with top-notch service and an unforgettable experience. Visit us and enjoy authentic specialties in the cozy ambiance of our restaurant.</p>
                <a href="" className="btn btn-primary">click here</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
