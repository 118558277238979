import React from 'react';

const Features = () => {
  return (
    <section id="features" className="features">
      <div className="slider_overlay">
        <div className="container">
          <div className="row">
            <div className="main_features_content_area wow fadeIn" data-wow-duration="3s">
              <div className="col-md-12">
                <div className="main_features_content text-left">
                  <div className="col-md-6">
                    <div className="single_features_text">
                      <h4>Special Recipes</h4>
                      <h3>Taste of Precious</h3>
                      <p>At Restaurant Mimar, we take pride in using top-quality, locally produced meat. Our passion for quality is reflected in every bite, offering our guests a unique taste and freshness.</p>
                      <p>By using carefully raised meat, we ensure that each Dish is not only delicious but also healthy. Visit us and experience the difference that locally produced meat, prepared with love and care, can make.</p>
                      <a href="" className="btn btn-primary">click here</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
