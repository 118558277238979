import React, { useEffect } from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Header from './components/Header';
import Home  from './components/Home';
import DishMenu  from './components/DishMenu.js';
import Slider from './components/Slider';
import About from './components/About';
import Features from './components/Features';
import Portfolio from './components/Portfolio';
import Packages from './components/Packages';
import Map from './components/Map';
import Footer from './components/Footer';
import './assets/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/animate/animate.css';
import './assets/css/plugins.css';
import './assets/css/style.css';
import './assets/css/responsive.css';

// Import jQuery initialization
import initJQuery from './assets/js/main.js';


import '../src/assets/js/vendor/jquery-1.11.2.min.js'
import '../src/assets/js/vendor/bootstrap.min.js'
import '../src/assets/js/jquery-easing/jquery.easing.1.3.js'
import '../src/assets/js/wow/wow.min.js'
import '../src/assets/js/plugins.js'
import '../src/assets/js/main.js'


// function App() {
//   useEffect(() => {
//     initJQuery();
//   }, []);

  const App = () => {
    return (
      <Router>
        <Header />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/dish-menu" element={<DishMenu />} />
          <Route path="*" element={<Navigate to="/dish-menu" />} />
        </Routes>
      </Router>
    );
  };
  

export default App;