// DishMenu.js
import React, { useState } from 'react';
import Map from '../components/Map';
import Footer from '../components/Footer';

const DishMenu = () => {
  const [activeCategory, setActiveCategory] = useState(null);

  const categories = [
    {
      id: 'topla-predjela',
      name: 'Topla predjela',
      image: 'https://t4.ftcdn.net/jpg/05/63/73/41/360_F_563734104_QAhd8PBoBcWv6NoAfdmeDcOzFdOF3Fi0.jpg',
      dishes: [
        { name: 'Begova čorba', ingredients: '(piletina,korjenasto povrće)', price: '6KM' },
        { name: 'Paradaiz supa', ingredients: '(paradaiz pire,bosiljak)', price: '7KM' },
        { name: 'Teleća supa', ingredients: '(temeljac,korjenasto povrće,teletina)', price: '8KM' },
        { name: 'Riblja čorba', ingredients: '(riba,povrće,paradaiz,sos)', price: '9KM' },
      ],
    },
    {
      id: 'hladna-predjela',
      name: 'Hladna predjela',
      image: 'https://media.istockphoto.com/id/1124545171/photo/ham-salami-and-sausages-mix.jpg?s=612x612&w=0&k=20&c=TGlb--GR8lWD-RkjOPeRMAoeeH6MEGkBjKzDmbwfrpk=',
      dishes: [
        { name: 'Peksimeti', ingredients: '(5 komada)', price: '5KM' },
        { name: 'Mladi sir', ingredients: '(100 grama)', price: '7KM' },
        { name: 'Tartar biftek', ingredients: '(biftek, 15 začina)', price: '25KM' },
        { name: 'Pršut', ingredients: '(100 grama)', price: '8KM' },
        { name: 'Hercegovački sir', ingredients: '(100 grama)', price: '7KM' },
        { name: 'Sir iz mijeha', ingredients: '(100 grama)', price: '10KM' },
        { name: 'Zakuska mimar', ingredients: '(pršut, mladi sir, hercegovački sir, sir iz mijeha, kajmak)', price: '28KM' },
      ],
    },
    {
      "id": "salate",
      "name": "Salate",
      "image": "https://media.istockphoto.com/id/1454741285/photo/roast-fish-and-vegetable-salad-on-wooden-background.jpg?s=612x612&w=0&k=20&c=Slmk-RLvdR3317E5W2UKLul4y1ZH3axjL2XCNOBZbhE=",
      "dishes": [
        {
          "name": "Miješana salata",
          "ingredients": "(kupus, zelena salata, paradaiz, krastavac, luk)",
          "price": "7KM"
        },
        {
          "name": "Kupus salata",
          "ingredients": "",
          "price": "6KM"
        },
        {
          "name": "Paradaiz salata",
          "ingredients": "",
          "price": "7KM"
        },
        {
          "name": "Zelena salata",
          "ingredients": "",
          "price": "6KM"
        },
        {
          "name": "Satrica salata",
          "ingredients": "(krastavac, jogurt, pavlaka, bijeli luk)",
          "price": "7KM"
        },
        {
          "name": "Šopska salata",
          "ingredients": "(povrće, sir)",
          "price": "10KM"
        },
        {
          "name": "Slatki kupus",
          "ingredients": "(kupus, mrkva, umak)",
          "price": "7KM"
        },
        {
          "dishes": [
            {
              "name": "Pileća salata – Chicken avocado",
              "ingredients": "(mix salata, bijeli umak, avokado)",
              "price": "17KM"
            },
            {
              "name": "Tuna salata",
              "ingredients": "(tuna, mix salata)",
              "price": "17KM"
            },
            {
              "name": "Caprecce salata",
              "ingredients": "(rikola, paradaiz, sir)",
              "price": "17KM"
            },
            {
              "name": "Steak salata",
              "ingredients": "(mix povrća, biftek)",
              "price": "25KM"
            }
          ]
        }
      ]
    },
    {
      "id": "mimar_salate",
      "name": "Mimar Salate",
      "image": "https://c4.wallpaperflare.com/wallpaper/712/112/861/tropical-chicken-salad-apple-orange-pineapple-wallpaper-preview.jpg",
      "dishes": [
        {
          "name": "Pileća salata – Chicken avocado",
          "ingredients": "(mix salata, bijeli umak, avokado)",
          "price": "17KM"
        },
        {
          "name": "Tuna salata",
          "ingredients": "(tuna, mix salata)",
          "price": "17KM"
        },
        {
          "name": "Capreccesalata",
          "ingredients": "(rikola, paradaiz, sir)",
          "price": "17KM"
        },
        {
          "name": "Steak salata",
          "ingredients": "(mix povrća, biftek)",
          "price": "25KM"
        }
      ]
    },
    {
      "id": "domaca_vegetarijanska_jela",
      "name": "Domaća Vegetarijanska Jela",
      "image": "https://png.pngtree.com/thumb_back/fw800/background/20240204/pngtree-ramadan-food-vegetarian-kabsa-with-rice-nuts-and-vegetables-image_15590306.png",
      "dishes": [
        {
          "name": "Đuveč",
          "ingredients": "(paprika, luk, paradaiz)",
          "price": "13KM"
        },
        {
          "name": "Pilav sa povrćem",
          "ingredients": "(pilav, mix povrća)",
          "price": "15KM"
        },
        {
          "name": "Pilav sa gljivama",
          "ingredients": "(pilav, gljive)",
          "price": "14KM"
        },
        {
          "name": "Pura",
          "ingredients": "(pura, maslac, jogurt)",
          "price": "12KM"
        },
        {
          "name": "Gljive sa žara",
          "ingredients": "",
          "price": "14KM"
        },
        {
          "name": "Mimar gljive",
          "ingredients": "(gljive, umak od gljiva)",
          "price": "15KM"
        },
        {
          "name": "Grilovano povrće",
          "ingredients": "(mix povrća)",
          "price": "16KM"
        },
        {
          "name": "Domaći rezanci sa povrćem",
          "ingredients": "",
          "price": "16KM"
        }
      ]
    },
    {
      "id": "snicle",
      "name": "Šnicle",
      "image": "https://t4.ftcdn.net/jpg/06/13/10/91/360_F_613109134_J8GL1DgsXTu7DTb8cwNsPlLUGURdRZ7Z.jpg",
      "dishes": [
        {
          "name": "Pileća bečka šnicla",
          "ingredients": "(piletina, prezla, jaja, pomfrit, umak)",
          "price": "18KM"
        },
        {
          "name": "Pileća rolana šnicla",
          "ingredients": "(piletina, prezla, jaja, sir, pršut, umak)",
          "price": "20KM"
        },
        {
          "name": "Piletina u šampinjon sosu",
          "ingredients": "(piletina, bešamel, gljive, pomfrit)",
          "price": "19KM"
        },
        {
          "name": "Piletina u curry umaku",
          "ingredients": "(piletina, bešamel, gljive, riža)",
          "price": "19KM"
        },
        {
          "name": "Bečka šnicla",
          "ingredients": "(teletina, prezla, jaja, umak)",
          "price": "24KM"
        },
        {
          "name": "Mimar naravno",
          "ingredients": "(teletina, demiglas, biber)",
          "price": "30KM"
        },
        {
          "name": "Teleća šnicla u šampinjon sosu",
          "ingredients": "(teletina, gljive, bešamel)",
          "price": "26KM"
        }
      ]
    }
    , {
      "id": "mimar_domaca_jela",
      "name": "Mimar Domaća Jela",
      "image": "https://i.pinimg.com/564x/b8/e5/f0/b8e5f0f9a1b44db08feead709a2f7945.jpg",
      "dishes": [
        {
          "name": "Japrak",
          "ingredients": "(meso, raštika, riža, krompir)",
          "price": "14KM"
        },
        {
          "name": "Dolma",
          "ingredients": "(meso, paprika, riža, krompir)",
          "price": "16KM"
        },
        {
          "name": "Mimar sahan",
          "ingredients": "(japrak, dolma)",
          "price": "18KM"
        },
        {
          "name": "Klepe",
          "ingredients": "(jufka, faširano meso, pavlaka)",
          "price": "21KM"
        },
        {
          "name": "Biber meso",
          "ingredients": "(meso, luk, senf, krompir)",
          "price": "19KM"
        },
        {
          "name": "Hercegovačka tava",
          "ingredients": "(meso, luk, senf, riža)",
          "price": "20KM"
        },
        {
          "name": "Mućkalica",
          "ingredients": "(meso, povrće, riža)",
          "price": "21KM"
        },
        {
          "name": "Nacionalna Mimar plata",
          "ingredients": "(japrak, dolma, biber meso, klepe, hercegovačka tava)",
          "price": "60KM"
        }
      ]
    },
    {
      "id": "rostilj",
      "name": "Roštilj",
      "image": "https://c4.wallpaperflare.com/wallpaper/435/472/868/barbecue-barbeque-bbq-cooked-wallpaper-preview.jpg",
      "dishes": [
        {
          "name": "Ćevapi",
          "ingredients": "(meso, luk, somun)",
          "price": "14KM"
        },
        {
          "name": "Pljeskavica",
          "ingredients": "(meso, luk, somun)",
          "price": "14KM"
        },
        {
          "name": "Sudžukice",
          "ingredients": "(meso, senf, pomfrit, somun)",
          "price": "16KM"
        },
        {
          "name": "Pileći file",
          "ingredients": "(piletina, sos, pomfrit, somun)",
          "price": "15KM"
        },
        {
          "name": "Teleći kotlet",
          "ingredients": "(teletina, sos, pomfrit)",
          "price": "21KM"
        },
        {
          "name": "Teleći ražnjić",
          "ingredients": "(teletina, sos, pomfrit)",
          "price": "16KM"
        },
        {
          "name": "Gurmanska pljeskavica",
          "ingredients": "(meso, pršut, sir, pomfrit, somun)",
          "price": "20KM"
        },
        {
          "name": "Ljuta pljeskavica",
          "ingredients": "(meso, čili, luk, somun)",
          "price": "14KM"
        }
      ]
    }
    ,
    {
      "id": "plate",
      "name": "Plate",
      "image": "https://restoran-mimar.ba/uploads/hrana_slike/plata_plata.jpg",
      "dishes": [
        {
          "name": "Roštiljska plata",
          "ingredients": "(ćevapi, sudžukica, kotlet, džigerica, pljeskavica, pileći file, ražnjići, pomfrit, somun)",
          "price": "50KM"
        },
        {
          "name": "Nacionalna Mimar plata",
          "ingredients": "(biber meso, hercegovačka tava, dolma, japrak, klepe, pire, riža, pavlaka)",
          "price": "60KM"
        },
        {
          "name": "Steak plata",
          "ingredients": "(biftek, ramstek, pileći steak, krompir, povrće)",
          "price": "75KM"
        },
        {
          "name": "Riblja plata",
          "ingredients": "(lubin, orada, pastrmka, dalmatinska garnitura, crveni file, povrće)",
          "price": "65KM"
        },
        {
          "name": "Vegetarijanska plata",
          "ingredients": "(riža, pire, đuveč, mix grilovano povrće)",
          "price": "45KM"
        },
        {
          "name": "Mimar roštilj plata",
          "ingredients": "(ćevapi, sudžukica, kotlet, džigerica, pljeskavica, pileći file, ražnjići, pomfrit)",
          "price": "90KM"
        }
      ]
    },
    {
      "id": "odlezalo_meso",
      "name": "Odležalo Meso",
      "image": "https://c0.wallpaperflare.com/preview/820/683/678/beef-beef-chuck-beef-steak-chunk.jpg",
      "dishes": [
        {
          "name": "Biftek",
          "ingredients": "(biftek, tost, pire, celer, prilog po želji)",
          "price": "45KM"
        },
        {
          "name": "Biftek u umaku od tartufa",
          "ingredients": "(biftek, tost, tartuf, celer, krompir)",
          "price": "60KM"
        },
        {
          "name": "Biftek zeleni papar",
          "ingredients": "(biftek, krojter, tost, krompir, umak od zelenog papra)",
          "price": "55KM"
        },
        {
          "name": "Biftek tagliata",
          "ingredients": "(biftek, krojter, tost, krompirići)",
          "price": "45KM"
        },
        {
          "name": "T-bone steak",
          "ingredients": "(500g mesa, maslac, ruzmarin, kroketi)",
          "price": "45KM"
        },
        {
          "name": "Ramstek",
          "ingredients": "(ramstek, krojter, tost, mix povrća sa žara)",
          "price": "22KM"
        },
        {
          "name": "Beef brisket",
          "ingredients": "(steak, demiglas, krastavac, kroketi)",
          "price": "30KM"
        },
        {
          "name": "Vezirske mahmudije",
          "ingredients": "(biftek, gljive, riža, povrće)",
          "price": "45KM"
        }
      ]
    }
    ,

    {
      "id": "ribe",
      "name": "Ribe",
      "image": "https://restoran-mimar.ba/uploads/hrana_slike/riba_riba.jpg",
      "dishes": [
        {
          "name": "Pastrmka",
          "ingredients": "(kuhani krompir, blitva)",
          "price": "18KM"
        },
        {
          "name": "Crveni file",
          "ingredients": "(krompir, blitva)",
          "price": "22KM"
        },
        {
          "name": "Lignje",
          "ingredients": "(krompir, blitva)",
          "price": "20KM"
        },
        {
          "name": "Lubin",
          "ingredients": "(krompir, blitva)",
          "price": "22KM"
        },
        {
          "name": "Orada",
          "ingredients": "(krompir, blitva)",
          "price": "22KM"
        }
      ]
    },
    {
      "id": "pasta",
      "name": "Pasta",
      "image": "https://c1.wallpaperflare.com/preview/563/525/135/background-diet-dinner-dry.jpg",
      "dishes": [
        {
          "name": "Pasta paradaiz",
          "ingredients": "(paradaiz, sos, začini)",
          "price": "13KM"
        },
        {
          "name": "Dalmatinska pasta / Oleo pasta",
          "ingredients": "(pasta, masline, bijeli luk)",
          "price": "12KM"
        },
        {
          "name": "Mimar pasta / Karbonara",
          "ingredients": "(pršut, salsa, žumance, parmezan)",
          "price": "16KM"
        },
        {
          "name": "Bolonjez",
          "ingredients": "(pasta, faširano meso, paradaiz, začini)",
          "price": "16KM"
        },
        {
          "name": "Njoki piletina pesto",
          "ingredients": "(piletina, njoki, pesto, bešamel)",
          "price": "18KM"
        },
        {
          "name": "Njoki pomodoro",
          "ingredients": "(njoki, paradaiz, pesto)",
          "price": "13KM"
        },
        {
          "name": "Pene četiri vrste sira",
          "ingredients": "",
          "price": "16KM"
        },
        {
          "name": "Tagliatele pršut salsa",
          "ingredients": "(pršut, pasta, parmezan, paradaiz)",
          "price": "22KM"
        },
        {
          "name": "Wok nudle piletina",
          "ingredients": "(nudle, piletina, povrće, soja, worcestershire, teriyaki)",
          "price": "16KM"
        }
      ]
    }

    ,

    {
      "id": "rizoto",
      "name": "Rižoto",
      "image": "https://c4.wallpaperflare.com/wallpaper/382/296/41/nature-food-wallpaper-preview.jpg",
      "dishes": [
        {
          "name": "Rižoto sa kozicama",
          "ingredients": "(riža, povrće, paradaiz, parmezan)",
          "price": "30KM"
        },
        {
          "name": "Pilav piletina",
          "ingredients": "(piletina, pilav, šalša)",
          "price": "15KM"
        },
        {
          "name": "Pilav teletina",
          "ingredients": "(teletina, pilav, bešamel)",
          "price": "19KM"
        },
        {
          "name": "Pilav sa povrćem",
          "ingredients": "(pilav, povrće)",
          "price": "15KM"
        }
      ]
    },
    {
      "id": "medaljoni",
      "name": "Medaljoni",
      "image": "path/to/image14.jpg",
      "dishes": [
        {
          "name": "Teleći medaljoni sa četiri vrste sira",
          "ingredients": "(teletina, četiri sira, pomfrit)",
          "price": "23KM"
        },
        {
          "name": "Teleći medaljoni u šampinjon sosu",
          "ingredients": "(teletina, gljive)",
          "price": "22KM"
        },
        {
          "name": "Teleći medaljoni gorgonzola sos",
          "ingredients": "",
          "price": "28KM"
        },
        {
          "name": "Teleći medaljoni na lovački način",
          "ingredients": "(teletina, pomfrit, demiglas)",
          "price": "32KM"
        }
      ]
    }
    ,
    {
      "id": "deserti",
      "name": "Deserti",
      "image": "../assets/images/newsone.jpg",
      "dishes": [
        {
          "name": "Baklava",
          "ingredients": "(orasi, tijesto)",
          "price": "8KM"
        },
        {
          "name": "Hurmašica",
          "ingredients": "(prhko tijesto)",
          "price": "4KM"
        },
        {
          "name": "Tufahija",
          "ingredients": "(jabuka, orasi)",
          "price": "5KM"
        },
        {
          "name": "Kadaif",
          "ingredients": "(orasi, tjestenina)",
          "price": "5KM"
        },
        {
          "name": "Smokvara",
          "ingredients": "(griz, pekmez od smokve)",
          "price": "4KM"
        },
        {
          "name": "Crumble",
          "ingredients": "(prhko tijesto sa jabukom)",
          "price": "8KM"
        },
        {
          "name": "Voćni kup",
          "ingredients": "(mix voća, sladoled, šlag)",
          "price": "10KM"
        },
        {
          "name": "Voćna salata",
          "ingredients": "(mix voća)",
          "price": "8KM"
        },
        {
          "name": "Banana split",
          "ingredients": "(banana, sladoled po želji)",
          "price": "11KM"
        },
        {
          "name": "Palačinci",
          "ingredients": "(nutela i sladoled)",
          "price": "7KM"
        }
      ]
    }
    ,
    {
      "id": "prilozi",
      "name": "Prilozi",
      "image": "path/to/image16.jpg",
      "dishes": [
        {
          "name": "Pomfrit",
          "ingredients": "",
          "price": "3KM"
        },
        {
          "name": "Blitva",
          "ingredients": "",
          "price": "3KM"
        },
        {
          "name": "Kuhani krompir",
          "ingredients": "",
          "price": "3KM"
        },
        {
          "name": "Riža",
          "ingredients": "",
          "price": "3KM"
        },
        {
          "name": "Pire krompir",
          "ingredients": "",
          "price": "3KM"
        },
        {
          "name": "Ajvar",
          "ingredients": "",
          "price": "3KM"
        },
        {
          "name": "Dresing",
          "ingredients": "(tartar, bijeli umak, krastavac sos)",
          "price": "3KM"
        },
        {
          "name": "Kajmak",
          "ingredients": "",
          "price": "3KM"
        },
        {
          "name": "Pire celer",
          "ingredients": "",
          "price": "5KM"
        },
        {
          "name": "Šampinjon sos",
          "ingredients": "",
          "price": "5KM"
        },
        {
          "name": "Gril povrće",
          "ingredients": "",
          "price": "5KM"
        },
        {
          "name": "Kroketi",
          "ingredients": "",
          "price": "6KM"
        }
      ]
    }

  ];


  const toggleCategory = (id) => {
    setActiveCategory((prev) => (prev === id ? null : id));
  };

  return (

    <div>
  <section>
    <div className="container">
      <div className="row">
        <div className="dish margin-top-10">
          <h1>Dish Menu</h1>
          <p>Explore our delicious dishes by category. Click on a category to see its dishes.</p>

          <div className="dish-menu">
            {categories.map((category) => (
              <div key={category.id} className="category-section">
                <div
                  className="category-header"
                  onClick={() => toggleCategory(category.id)}
                  style={{ backgroundImage: `url(${category.image})` }}
                >
                  <h2 className="category-name">{category.name}</h2>
                </div>
                <div
                  className={`category-content ${activeCategory === category.id ? 'open' : ''}`}
                >
                  {category.dishes.map((dish, index) => (
                    <div key={index} className="dish-item">
                      <div className="dish-header">
                        <span className="dish-name">{dish.name}</span>
                        <span className="dish-dots"></span>
                        <span className="dish-price">{dish.price}</span>
                      </div>
                      <div className="dish-ingredients" style={{ color: 'yellow', textAlign: 'left' }}>
                        {dish.ingredients}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
  <Map />
  <Footer />
</div>

                
                
  );
};

export default DishMenu;
