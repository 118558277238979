import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'jquery.easing';
import WOW from 'wowjs';

import './assets/js/plugins.js';
import './assets/js/main.js';

new WOW.WOW().init();

ReactDOM.render(<App />, document.getElementById('root'));
