import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Slider from '../components/Slider';
import About from '../components/About';
import Features from '../components/Features';
import Portfolio from '../components/Portfolio';
import Packages from '../components/Packages';
import Map from '../components/Map';
import Footer from '../components/Footer';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      if (location.state.scrollToTop) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else if (location.state.scrollToId) {
        const { scrollToId } = location.state;
        const element = document.getElementById(scrollToId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        } else {
          console.log(`Element with ID ${scrollToId} not found`);
        }
      }
    }
  }, [location.state]);

  return (
    <div>
      <section id="home">
        <Slider />
      </section>
      <section id="abouts">
        <About />
      </section>
      <section id="features">
        <Features />
      </section>
      <section id="portfolio">
        <Portfolio />
      </section>
      <section id="packages">
        <Packages />
      </section>
      <section id="map">
        <Map />
      </section>
      <section id="footer_widget">
        <Footer />
      </section>
    </div>
  );
};

export default Home;
