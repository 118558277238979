import React from 'react';

const Slider = () => {
  return (
    <section id="slider" className="slider">
      <div className="slider_overlay">
        <div className="container">
          <div className="row">
            <div className="main_slider text-center">
              <div className="col-md-12">
                <div className="main_slider_content wow zoomIn" data-wow-duration="1s">
                  <h1>Restaurant Mimar</h1>
                  <button href="" className="btn-lg">Click here</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slider;
