import { Link, useNavigate, useLocation } from 'react-router-dom';



const handleScroll = (navigate, id, location) => {
  return (event) => {
    event.preventDefault();
    if (id === 'home' || id === 'dish-menu') {
      if (location.pathname !== `/${id}`) {
        navigate(`/${id}`, { state: { scrollToTop: true } });
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        // If the element does not exist, redirect to home and try to scroll
        if (location.pathname !== '/home') {
          navigate('/home', { state: { scrollToId: id } });
        } else {
          console.log(`Element with ID ${id} not found`);
        }
      }
    }
  };
};

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <header id="home" className="navbar-fixed-top">
      <div className="header_top_menu clearfix">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-md-offset-3 col-sm-12 text-right">
              <div className="call_us_text">
                <a href="tel:+38761454357">
                  <i className="fa fa-phone"></i>+387 61 454 357
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="head_top_social text-right">
                <a
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=61558264442701"
                  rel="noreferrer"
                >
                  <i className="fa fa-facebook"></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/mimar.restoran/"
                  rel="noreferrer"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_menu_bg">
        <div className="container">
          <div className="row">
            <nav className="navbar navbar-default">
              <div className="container-fluid">
                <div className="navbar-header">
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#bs-example-navbar-collapse-1"
                    aria-expanded="false"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <a className="navbar-brand our_logo" href="#">
                    <img src="../assets/images/logo.png" alt="logo" />
                  </a>
                </div>
                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                  <ul className="nav navbar-nav navbar-right">
                    <li>
                      <a href="#home" onClick={handleScroll(navigate, 'home', location)}>Home</a>
                    </li>
                    <li>
                      <a href="#abouts" onClick={handleScroll(navigate, 'abouts', location)}>Menu</a>
                    </li>
                    <li>
                      <a href="#portfolio" onClick={handleScroll(navigate, 'portfolio', location)}>Delivery</a>
                    </li>
                    <li>
                      <a href="#footer_widget" onClick={handleScroll(navigate, 'footer_widget', location)} className="booking">Table Booking</a>
                    </li>
                    <li>
                      <a href="#dish-menu" onClick={handleScroll(navigate, 'dish-menu', location)}>Dish Menu</a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
