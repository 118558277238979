import React from 'react';

const Portfolio = () => {
  return (
    <section id="portfolio" class="portfolio">
    <div class="container">
      <div class="row">
        <div class="portfolio_content text-center  wow fadeIn" data-wow-duration="5s">
          <div class="col-md-12">
            <div class="head_title text-center">
              <h4>Delightful</h4>
              <h3>Experience</h3>
            </div>
            <div class="main_portfolio_content">
              <div class="col-md-3 col-sm-4 col-xs-6 single_portfolio_text"> <img src="assets/images/p1.jpg" alt="" />
                <div class="portfolio_images_overlay text-center">
                  <h6>Dish Name Test</h6>
                  <p class="product_price">0€</p> <a href="" class="btn btn-primary">Click here</a>
                </div>
              </div>
              <div class="col-md-3 col-sm-4 col-xs-6 single_portfolio_text"> <img src="assets/images/p2.jpg" alt="" />
                <div class="portfolio_images_overlay text-center">
                  <h6>Dish Name Test</h6>
                  <p class="product_price">0€</p> <a href="" class="btn btn-primary">Click here</a>
                </div>
              </div>
              <div class="col-md-3 col-sm-4 col-xs-6 single_portfolio_text"> <img src="assets/images/p3.jpg" alt="" />
                <div class="portfolio_images_overlay text-center">
                  <h6>Dish Name Test</h6>
                  <p class="product_price">0€</p> <a href="" class="btn btn-primary">Click here</a>
                </div>
              </div>
              <div class="col-md-3 col-sm-4 col-xs-6 single_portfolio_text"> <img src="assets/images/p4.jpg" alt="" />
                <div class="portfolio_images_overlay text-center">
                  <h6>Dish Name Test</h6>
                  <p class="product_price">0€</p> <a href="" class="btn btn-primary">Click here</a>
                </div>
              </div>
              <div class="col-md-3 col-sm-4 col-xs-6 single_portfolio_text"> <img src="assets/images/p5.jpg" alt="" />
                <div class="portfolio_images_overlay text-center">
                  <h6>Dish Name Test</h6>
                  <p class="product_price">0€</p> <a href="" class="btn btn-primary">Click here</a>
                </div>
              </div>
              <div class="col-md-3 col-sm-4 col-xs-6 single_portfolio_text"> <img src="assets/images/hej1.jpg" alt="" />
                <div class="portfolio_images_overlay text-center">
                  <h6>Dish Name Test</h6>
                  <p class="product_price">0€</p> <a href="" class="btn btn-primary">Click here</a>
                </div>
              </div>
              <div class="col-md-3 col-sm-4 col-xs-6 single_portfolio_text"> <img src="assets/images/news1.jpg" alt="" />
                <div class="portfolio_images_overlay text-center">
                  <h6>Dish Name Test</h6>
                  <p class="product_price">0€</p> <a href="" class="btn btn-primary">Click here</a>
                </div>
              </div>
              <div class="col-md-3 col-sm-4 col-xs-6 single_portfolio_text"> <img src="assets/images/hej3.jpg" alt="" />
                <div class="portfolio_images_overlay text-center">
                  <h6>Dish Name Test</h6>
                  <p class="product_price">0€</p> <a href="" class="btn btn-primary">Click here</a>
                </div>
              </div>
            </div>
          </div>
        <a href="dish-menu" class="btn btn-primary margin-top-60">click here</a>
        </div>
      </div>
    </div>
  </section>
  );
};

export default Portfolio;
