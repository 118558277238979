import React from 'react';

const Map = () => {
  return (
    <div className="map-pin-location">
      <div className="map-embed">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11607.275648604831!2d17.8141988!3d43.3389783!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134b4318353d47d3%3A0x5597d9ffcb0038d2!2sRestaurant%20Mimar!5e0!3m2!1sbs!2sba!4v1713457196457!5m2!1sbs!2sba" width="100%" height="400vh" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  );
};

export default Map;
