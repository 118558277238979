import React from 'react';

const Footer = () => {
  return (
    <>
      <section id="footer_widget" className="footer_widget">
        <div className="container">
          <div className="row">
            <div className="footer_widget_content text-center">
              <div className="col-md-4">
                <div className="single_widget wow fadeIn" data-wow-duration="2s">
                  <h3>Location of the restaurant</h3>
                  <div className="single_widget_info">
                    <p>
                      <span> Mala Tepa bb, Mostar</span>
                      <span className="phone_email">Phone: +387 61 454 357</span>
                      <span>Email: info@restoran-mimar.ba</span>
                    </p>
                  </div>
                  <div className="footer_socail_icon">
                    <a target="_blank" href="https://www.facebook.com/profile.php?id=61558264442701" rel="noreferrer"><i className="fa fa-facebook"></i></a>
                    <a target="_blank" href="https://www.instagram.com/mimar.restoran/" rel="noreferrer"><i className="fa fa-instagram"></i></a>
                    <a target="_blank" href="https://www.linkedin.com/in/azer-pilavdzic/" rel="noreferrer"><i className="fa fa-linkedin"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="single_widget wow fadeIn" data-wow-duration="5s">
                  <h3>Make reservation</h3>
                  <div className="single_widget_form text-left">
                    <form action="#" id="formid">
                      <div className="form-group">
                        <input disabled type="text" className="form-control" name="name" placeholder="first name" required="" />
                      </div>
                      <div className="form-group">
                        <input disabled type="email" className="form-control" name="email" placeholder="Email" required="" />
                      </div>
                      <div className="form-group">
                        <input disabled type="text" className="form-control" placeholder="Subject" />
                      </div>
                      <div className="form-group">
                        <textarea disabled className="form-control" name="message" rows="3" placeholder="Message"></textarea>
                      </div>
                      <input disabled type="submit" value="click here" className="btn btn-primary" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="footer" className="footer">
        <div className="container text-center">
          <div className="row">
            <div className="col-sm-12">
              <div className="copyright wow zoomIn" data-wow-duration="3s">
                <p>All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="scrollup">
        <a href="#"><i className="fa fa-chevron-up"></i></a>
      </div>
    </>
  );
};

export default Footer;
